/**
  Defaults
 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
  Main
 */

@font-face {
  font-family: KMM;
  src: url(../fonts/Fabulist.otf);
}

.overlay {
  height: 200%;
  width: 200%;
  top: -50%;
  left: -50%;
  position: fixed;
  z-index: 100;
  pointer-events: none;
  animation: noise 1s steps(8,end) infinite both;
  background-image: url('noise.png');
}

#particles-js {
  opacity: 0.1;
  height: calc(100vh);
  pointer-events: none;
}

.mid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1000px;
  width: 1000px;
  margin-top: 75px;
}

body {
  font-family: KMM, sans-serif;
  color: white;
  overflow: hidden;
  user-select: none;
  box-sizing: border-box;
  background: rgb(32,46,47);
  background: radial-gradient(circle, rgba(32,46,47,1) 0%, rgba(0,0,0,1) 100%);
  min-width: 415px;
}

@keyframes noise {
  0% {
    transform: translateX(0px, 0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}

.large-view {
  position: fixed;
  z-index: 500;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 40px;
    width: 40px;
  }

  .back {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .main {
    position: relative;
    z-index: 2;
    max-height: 90%;
    max-width: 90%;
  }
}

@keyframes typing {
  0% { width: 0 }
  50% { width: 100% }
  100% { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes midIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.midIn {
  opacity: 0;
  animation: midIn 1s forwards;
  animation-delay: 5s;
}

@keyframes fadeInSeven {
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.animFade {
  transform: translateY(10px);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.animFade.one {
  animation-delay: 0.5s;
}

.animFade.two {
  animation-delay: 1s;
}

.animFade.three {
  animation-delay: 1.5s;
}

.animFade.four {
  animation-delay: 2s;
}

.animFade.five {
  animation-delay: 2.5s;
}

.animFade.six {
  animation-delay: 3s;
}

.animFade.seven {
  transform: translate(-50%, 10px);
  animation: fadeInSeven 1s forwards;
  animation-delay: 3.5s;
}

.cursor {
  pointer-events: none;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;

    circle {
      fill: #f7f8fa;
    }
  }
}

  .name {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  flex-direction: column;
  z-index: 10;

  .title-cover {
    display: inline-block;
    align-self: center;
  }

  h1 {
    border-right: 1px solid orange;
    box-sizing: border-box;
    font-size: 20px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: bold;
    animation:
      typing 3.5s steps(12, end) infinite,
      blink-caret .75s step-end infinite;
    overflow: hidden;
    white-space: nowrap;
    margin: 50px auto 10px auto;
  }

  h3 {
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: lowercase;
  }

  h4 {
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: lowercase;
    margin-top: 5px;
  }
}

.left-bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 90px;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.right-bar {
  position: absolute;
  right: 0;
  height: 100%;
  width: 90px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  p {
    text-align: center;
    letter-spacing: 1px;
    margin-left: 10px;
    margin-right: 10px;

    &:first-of-type {
      margin-top: 20px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

.left-bar img, .right-bar img {
  max-width: 30px;
  max-height: 30px;
  margin: 12.5px 0;
  opacity: 0.5;
  transition: 1s;
}

.left-bar img:hover, .right-bar img:hover {
  transition: 0.1s;
  opacity: 1;
}

.left-bar > img.active, .right-bar > img.active {
  opacity: 1;
  transform: scale(1.2);
}

.middle-bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
}

.middle-bar2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
}

.bottom-left {
  position: absolute;
  left: 140px;
  bottom: 50px;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: bold;
  z-index: 10;
}

.bottom-middle {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  a {
    margin: 0 7.5px;
  }

  img {
    height: 14px;
  }

  i, img {
    color: white;
    opacity: 0.5;
    transition: 1s;

    &:hover {
      transition: 0.1s;
      opacity: 1;
    }
  }
}

.bottom-right {
  position: absolute;
  right: 140px;
  bottom: 50px;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: bold;
  z-index: 10;
}

.gallery {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  max-height: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: lowercase;
  }

  p {
    font-family: "Courier New";
    line-height: 1.5em;

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
}

.bar {
  height: 20px;
  width: 200px;
  margin-top: 4.5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@keyframes loading {
  0% { width: 0 }
  100% { width: 100% }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0 }
}

.loading {
  animation: fadeOut 1s forwards linear;
  animation-delay: 4s;
  margin-top: -21px;
}

.bar-progress {
  height: 100%;
  width: 0px;
  background-color: orange;
  animation: loading 4s forwards linear;
}

*, *:hover {
  cursor: none;
}

.images {
  display: flex;
  justify-content: center;

  img {
    max-width: 30%;
    margin: 2.5%;
    box-sizing: border-box;
    border: 1px solid white;
    transition: 1s;
    filter: brightness(50%) grayscale(100%);
    align-self: center;

    &:hover {
      transition: 0.1s;
      transform: scale(1.1);
      filter: brightness(100%) grayscale(0%);
    }
  }
}

/**
  Responsiveness
 */

@media screen and (max-width: 768px) {
  .bottom-left {
    bottom: 95px;
    text-align: center;
    left: auto;
    right: auto;
    width: 100%;
  }
  .bottom-right {
    bottom: 60px;
    text-align: center;
    left: auto;
    right: auto;
    width: 100%;
  }
  .bottom-middle {
    bottom: 25px;
  }
  .gallery p {
    line-height: 1em;
  }
  .images:not(:empty) {
    margin-top: 10px;
  }
  .cursor {
    display: none;
  }
  #particles-js {
    opacity: 0;
  }
  .gallery p {
    font-size: 14px;
  }
  .left-bar, .right-bar {
    width: 60px;
  }
  .left-bar img, .right-bar img {
    margin: 10px 0;
  }
  .name h1 {
    margin: 25px auto 10px auto;
  }
}